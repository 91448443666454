.keen-slider__slide:not(.slide-visible) {
  .product-card {
    a,
    button,
    input,
    textarea,
    select {
      visibility: hidden;
    }
  }
}
