.input-effect {
  position: relative;

  .effect,
  input:-webkit-autofill {
    z-index: 1;
    padding: 25px 0 5px;
    height: auto;
    border: none;
    border-bottom: 1px solid var(--color-gray-dark);
    background-color: transparent;

    & ~ label,
    & ~ .input-label-container label {
      position: absolute;
      left: 0;
      top: 15px;
      transition: 0.3s;
      z-index: 0;
      pointer-events: none;
    }

    & ~ .focus-border {
      &::after {
        content: '';
        position: absolute;
        top: auto;
        bottom: 0;
        right: auto;
        width: 0;
        height: 1px;
        background-color: var(--color-dark);
        transition: 0.5s;
        transition-delay: 0.5s;
      }
    }

    &:focus {
      border-color: var(--color-gray-dark);

      & ~ .focus-border {
        &::after {
          width: 100%;
          transition: 0.5s;
          transition-delay: 0.3s;
        }
      }

      & ~ label,
      & ~ .input-label-container label {
        top: -5px;
        transition: 0.3s;
        z-index: 1;
      }
    }

    &:not(:placeholder-shown) {
      & ~ label,
      & ~ .input-label-container label {
        top: -5px;
        transition: 0.3s;
        z-index: 2;
      }

      & ~ .focus-border {
        &::after {
          background-color: var(--color-dark);
          width: 100%;
        }
      }
    }

    &::placeholder {
      color: transparent;
    }

    &:not([class*='ng-invalid-']) {
      & ~ .focus-border {
        &::before {
          content: '';
          transform: scaleX(-1) rotate(135deg);
          transform-origin: left top;
          border-right: 2px solid transparent;
          border-top: 2px solid transparent;
          display: block;
          height: 20px;
          position: absolute;
          right: 15px;
          margin-top: -15px;
          width: 8px;
          animation: check 0.8s;
          border-color: var(--color-success);
        }
      }
    }

    &.password {
      padding-right: 25px;

      & ~ .focus-border::before {
        display: none;
      }
    }

    &.ng-invalid-pattern,
    .ng-invalid-minlength,
    .ng-invalid-minlength {
      & ~ .focus-border::after {
        background-color: var(--color-red) !important;
        width: 100%;
      }
    }
  }

  select.effect {
    padding: 5px 0;
    height: 36px;

    & ~ .focus-border {
      &::before {
        border-color: transparent !important;
      }
    }

    &:not(:focus) ~ .focus-border {
      &::after {
        width: 0;
      }
    }

    &:focus,
    &.ng-valid-parse:not(:focus) {
      & ~ .focus-border::after {
        width: 100%;
      }
    }
  }

  .toggle_password {
    position: absolute;
    top: 22px;
    right: 0;
    cursor: pointer;
    font-family: 'Font Awesome 6 Pro';
    font-size: 18px;
    font-weight: lighter;
  }
}

.required-field {
  label::after {
    content: ' *';
    color: var(--color-red);
  }
}
