.slick-slide:not(.slick-active) {
  .card-button {
    display: none;
  }
}

.overflow-hidden .builder-carousel {
  .slick-slide:not(.slick-active) div {
    display: none;
  }
}
