//Adjust elements for component: add-to-cart__sticky in product page
//Chat message
iframe#launcher {
  z-index: 980 !important;
  + iframe {
    z-index: 980 !important;
  }
}
.product-page {
  iframe#launcher {
    transition: all 400ms ease-in-out;
    &[style*='-widgetOnLoad'] {
      bottom: 115px !important;
    }
    &[style*='-launcherOnOpen'] {
      bottom: 20px !important;
    }
    + iframe {
      bottom: 162px !important;
    }
    @media (max-width: 767.9px) {
      &[style*='-widgetOnLoad'] {
        bottom: 138px !important;
      }
      &[style*='-launcherOnOpen'] {
        bottom: 20px !important;
      }
      + iframe {
        bottom: 182px !important;
      }
    }
    @media only screen and (max-width: 400px) {
      &,
      &[style*='-widgetOnLoad'] {
        bottom: 130px !important;
      }
      &[style*='-launcherOnOpen'] {
        bottom: 20px !important;
      }
      + iframe {
        bottom: 174px !important;
      }
    }
  }
}

.footer_container {
  #btnOneTrust {
    /*required styles defined by the client*/
    display: block !important;
    position: fixed;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1px solid var(--color-primary-color);
    padding: 8px;
    color: var(--color-primary-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    background: var(--color-primary-white);
    z-index: 980;
    /*optional styles to match store styles*/
  }
}

//adjust cookies menssage
.product-page__wrapper {
  .footer_container {
    #btnOneTrust {
      right: 16px;
      bottom: 118px;
      @media only screen and (max-width: 767px) {
        bottom: 137px;
        right: 0px;
      }
    }
  }
}
