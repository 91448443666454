hr.separatorComparisonTable {
  height: 1px;
  color: #3d3d41;
  border: 1px solid #3d3d41;
  opacity: 0.1;
}

.tooltip {
  position: absolute;
  z-index: 999;
  opacity: 1;

  width: 350px;
  max-width: calc(100vw - 40px);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgb(61, 61, 65);
  border-image: initial;
  padding: 20px;
  background-color: var(--color-primary-white);
  @media only screen and (max-width: 768px) {
    &.first-tooltip {
      transform: translateX(-10px);
    }
    &.last-tooltip {
      transform: translateX(10px);
    }
  }
  p {
    margin-bottom: 10px;
  }
  hr {
    margin: 10px 0;
    width: 100%;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
