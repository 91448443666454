body {
  overflow-x: hidden;
  overflow-y: auto;
}
.mob-menu_open {
  @media only screen and (max-width: 991.9px) {
    overflow: hidden;
    #offcanvas_minicart-trigger {
      pointer-events: none;
    }
  }
}

//typography

.section-header {
  .section-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    text-transform: uppercase;
    margin: 50px 0 40px;
    text-align: center;
  }
}

.poh-page {
  .empty-color-metaproduct {
    height: 27px;
  }
  .empty-bv-rating-summary {
    height: 24px;
  }
  .product-card__price {
    display: none !important;
  }
}
